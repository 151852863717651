import { getDynamicYieldContextScript, getDynamicYieldScripts } from '~/tracking/trackingutils';

export default {
	head() {
		return {
			script: this.dynamicYieldContextScript,
			__dangerouslyDisableSanitizersByTagID: {
				DY_recommendationContext: ['innerHTML'],
			},
		};
	},
	computed: {
		dynamicYieldContextScript() {
			return [getDynamicYieldContextScript(this), ...getDynamicYieldScripts(this)];
		},
	},
};
