import { render, staticRenderFns } from "./Modal.vue?vue&type=template&id=78d367c0&scoped=true&"
import script from "./Modal.ts?vue&type=script&lang=ts&"
export * from "./Modal.ts?vue&type=script&lang=ts&"
import style0 from "./Modal.scss?vue&type=style&index=0&id=78d367c0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../shop/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78d367c0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Overlay: require('/opt/jenkins/workspace/ospbuild/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/Overlay/Overlay.vue').default,Ico: require('/opt/jenkins/workspace/ospbuild/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/Ico/Ico.vue').default,SectionTitle: require('/opt/jenkins/workspace/ospbuild/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/SectionTitle/SectionTitle.vue').default,KeyboardAvoidingView: require('/opt/jenkins/workspace/ospbuild/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/KeyboardAvoidingView/KeyboardAvoidingView.vue').default})
